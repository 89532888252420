import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlatformChooserComponent } from './components/platform-chooser/platform-chooser.component';
import { AppListComponent } from './components/app-list/app-list.component';
import { VersionListComponent } from './components/version-list/version-list.component';
import { VersionDownloadComponent } from './components/version-download/version-download.component';
//import { SigninReturnComponent } from './components/signin-return/signin-return.component';
//import { SilentRenewComponent } from './components/silent-renew/silent-renew.component';
import { UnauthenticatedComponent } from './components/unauthenticated/unauthenticated.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
//import { AuthService } from './services/auth.service';
import { MsalGuard } from './msal';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: 'signinReturn', component: SigninReturnComponent },
  //{ path: 'silentRenew', component: SilentRenewComponent },
  { path: 'unauthenticated', component: UnauthenticatedComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'home', component: PlatformChooserComponent, canActivate: [MsalGuard] },
  { path: 'appList/:os', component: AppListComponent, canActivate: [MsalGuard] },
  { path: 'versionList/:os/:app', component: VersionListComponent, canActivate: [MsalGuard] },
  { path: 'download/:os/:app/:version', component: VersionDownloadComponent, canActivate: [MsalGuard] },
  { path: '**', redirectTo: 'home' }
  /* { path: 'home', component: PlatformChooserComponent, canActivate: [AuthService] },
  { path: 'appList/:os', component: AppListComponent, canActivate: [AuthService] },
  { path: 'versionList/:os/:app', component: VersionListComponent, canActivate: [AuthService] },
  { path: 'download/:os/:app/:version', component: VersionDownloadComponent, canActivate: [AuthService] },
  { path: '**', redirectTo: 'home', canActivate: [AuthService] } */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
