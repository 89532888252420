<h5 *ngIf="versions">{{versions[0].title}} - Versions <back-button></back-button></h5>
<div class="row mt-3 border" *ngFor="let version of versions">
  <div class="col">
    <div class="row">
      <div class="col">
        <h5>{{version.versionNum}} <span class="badge badge-info" [innerHTML]=version.specialNote></span></h5>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p><u>Release Notes:</u></p>
        <p [innerHTML]=version.releaseNotes></p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <a class="btn btn-primary" [routerLink]="['/', 'download', os, version.appID, version.versionNum]">Download</a>&nbsp;
      </div>
    </div>

  </div>

</div>
<br>
<back-button></back-button>
