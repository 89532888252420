import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentClient, DownloadContentModel } from 'src/app/services/_gen/api';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'version-download',
  templateUrl: './version-download.component.html',
  styleUrls: ['./version-download.component.css']
})
export class VersionDownloadComponent implements OnInit {

  downloadContent: DownloadContentModel;
  os: string;
  app: string;
  version: string;

  constructor(private route: ActivatedRoute,
    private contentClient: ContentClient) { }

  ngOnInit() {
    this.os = this.route.snapshot.paramMap.get('os');
    this.app = this.route.snapshot.paramMap.get('app');
    this.version = this.route.snapshot.paramMap.get('version');

    this.contentClient.getDownloadContentItem(this.os, this.app, this.version)
      .subscribe(downloadContent => this.downloadContent = downloadContent);
  }

  downloadClick() {
    console.log('downloadClick');

    this.contentClient.getDirectDownloadUrl(this.os, this.app, this.version, this.downloadContent.fileName)
      .subscribe(url => {
        window.location.href = url;
      });
  }
}
