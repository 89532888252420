import { Component, OnInit } from '@angular/core';
import { ContentClient, PlatformModel } from 'src/app/services/_gen/api';
//import { HttpClient } from '@angular/common/http';
//import { environment } from '../../../environments/environment';

@Component({
  selector: 'platform-chooser',
  templateUrl: './platform-chooser.component.html',
  styleUrls: ['./platform-chooser.component.css']
})
export class PlatformChooserComponent implements OnInit {
  public platforms: PlatformModel[];
  //platforms;

  constructor(
    private contentClient: ContentClient
  ) { }

  //constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getPlatforms();
  }

  public getPlatforms() {
    this.contentClient.getPlatformList()
      .subscribe(platforms => this.platforms = platforms);
  }

  /* public getPlatforms() {
    // TODO - Get rid of the hard-coding
    this.http.get(environment.apiUrl + '/v1/Platforms')
      .subscribe(platforms => {
        this.platforms = platforms;
      });
  } */
}
