import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentClient, ApplicationModel } from 'src/app/services/_gen/api';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.css']
})
export class AppListComponent implements OnInit {

  apps: ApplicationModel[];
  os: string;

  constructor(private route: ActivatedRoute, 
    private contentClient: ContentClient) { }

  ngOnInit() {
    this.os = this.route.snapshot.paramMap.get('os');
    this.contentClient.getApplicationList(this.os)
      .subscribe(apps => this.apps = apps);
  }
}
