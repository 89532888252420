import { Environment } from './environment-schema';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: true,
  envName: 'test',
  rollbarAccessToken: 'fe26dbf3864c48e8a81cf90218be9db2',
  clientId: '77a2131f-24d5-439d-9325-4311947a8b3e',
  serviceInfoOverride: {
    SideLoaderContentService: {
      baseUrl: 'https://rgl-mobile-sideload-fn-test-eastus.azurewebsites.net/api'  // this is used?
    }
  },
  authority: 'https://login.microsoftonline.com/49793faf-eb3f-4d99-a0cf-aef7cce79dc1',
  apiUrl: 'https://rgl-mobile-sideload-fn-test-eastus.azurewebsites.net/api',
  protectedResource: 'https://rgl-mobile-sideload-fn-test-eastus.azurewebsites.net',
  protectedResourceScope: 'https://rgl-mobile-sideload-fn-test-eastus.azurewebsites.net/user_impersonation',
  redirectUri: 'https://testapps.piedmont-airlines.com'
};
