<h5>{{os}} Apps <back-button></back-button></h5>
<div class="row mt-3 border" *ngFor="let app of apps">
  <div class="col">

    <div class="row mb-2">
      <div class="col-xs-1">
          <img src='{{app.advertisingImage}}'/>
      </div>
      <div class="col">
        <h5>{{app.title}}</h5>
        <p [innerHTML]=app.helpContent></p>
      </div>
  </div>
  
    <div class="row mb-3">
      <div class="col">
        <a class="btn btn-primary" [routerLink]="['/', 'download', os, app.appID, app.latestVersion]">Download</a>&nbsp;
        <a class="btn btn-primary" [routerLink]="['/', 'versionList', os, app.appID]">Other Versions</a>
      </div>
    </div>

  </div>
</div>
<br>
<back-button></back-button>
