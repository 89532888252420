import { Component, OnInit } from '@angular/core';
import { MsalService } from '../../msal';

@Component({
  selector: 'psa-app-unauthenticated',
  templateUrl: './unauthenticated.component.html'
})
export class UnauthenticatedComponent implements OnInit {
    
    constructor(
        private authService: MsalService
    ) { }

    ngOnInit() {
        if (this.authService.instance.getAllAccounts().length > 0) {
            console.log(this.authService.instance.getAllAccounts());
            this.authService.logout();
        }
    }
}