import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentClient, VersionModel } from 'src/app/services/_gen/api';

@Component({
  selector: 'app-version-list',
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.css']
})
export class VersionListComponent implements OnInit {

  versions: VersionModel[];
  os: string;

  constructor(private route: ActivatedRoute, 
    private contentClient: ContentClient) { }

  ngOnInit() {
    this.os = this.route.snapshot.paramMap.get('os');
    const app = this.route.snapshot.paramMap.get('app');

    this.contentClient.getVersionList(app)
      .subscribe(versions => this.versions = versions);
  }

}
